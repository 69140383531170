import {getAction, getAllList, postAction,httpAction,postRefreshAction} from "@/api/manage";
import {downloadJasperExcelByMethodAndByUrlAndParamsAndFileName, printPdfDoc} from "@/utils/downloadJasper";

export default {
    data() {
        return {
            queryForm: {},
            filterForm: {},
            initData: {}, //原始的列表查询需要传递的参数，除了分页的公共参数之外
            tableData: [],
            problemTypeList:[],
            loading: false,
            form: {},
            ipagination: {
                currentPage: 1,
                pageSize: 50,
                pageSizes: [50, 100, 200, 300, 400, 500],
                total: 0
            },
            isorter: {
                prop: '',
                order: ''
            },
            selectedRowKeys: [],
            selectionRows: [],
            selectedRowOrderNos: [],
            selectedRowOrderNosCheck: [],   //订单号查重

            disableMixinMounted: false,
            //是否使用post请求查询 默认false
            isUsePost:false,

            //业务员数据
            salesmanlist: [],
            //添加人数据
            userlist: [],
            //国家基础数据
            countrylist: [],
            //省基础数据
            statelist: [],
            //市基础数据
            citylist: [],
            //币种基础数据
            curlist: [],

            nameList: [],
            cnamelist: [],
            emailList: [],
            //港口基础数据
            bdPortList: [],
            allBdportList: [],
            formData: {
                pageIndex: 1,
                pageSize: 1000
            },
            //费用类别基础数据
            feeTypeList: [],
            //报价类型说明基础数据
            priceExplainList: [],
            //获取所有供应商
            allSupplierList: [],
            //获取所有文章类别
            allArticleTypeList: [],
            url: {
                dict: '/sys/dict/getDictItems/'
            },
            dictMap: {},
            dictlist: [],
            dictCodes: [],
            allList: [],
            allListMap: {},
            isFind: '',
            pdfUrl: '',
            enabledCreatetime:true,
            //导出按钮
            exportLoading:false,
            //所有的列表数据
            allTableData:[],
            tableRef:null,//设置了滚动的那个盒子
            tableWrap:null,
            fixLeft:null,
            fixRight:null,
            tableFixedLeft:null,
            tableFixedRight:null,
            //是否需要上的滚动条
            isNeedTop:false,
            customerFind:false,
            needQueryDictCodes:[],
            queryDictCodeListMap:{},

        }
    },
    directives: {
        'el-select-loadmore': {
            bind(el, binding) {
                const SELECTWRAP_DOM = el.querySelector(
                    '.el-select-dropdown .el-select-dropdown__wrap'
                );
                SELECTWRAP_DOM.addEventListener('scroll', function () {
                    const condition =
                        this.scrollHeight - this.scrollTop <= this.clientHeight;
                    if (condition) {
                        binding.value();
                    }
                });
            }
        }
    },
    created: function () {
        //把所有基础数据清空
        // console.log('需要查询的字典',this.dictCodes);
        if (this.dictCodes) {
            for (var i = 0; i < this.dictCodes.length; i++) {
                if (this.dictCodes[i]) {
                    this.getDictList(this.dictCodes[i]);
                }
            }
        }
        if (this.isFind) {
            this.initAllList();
        }


        // console.log('获取的字典map',this.dictMap);
        if (this.needQueryDictCodes && this.needQueryDictCodes.length > 0) {
            let realNeedQueryDictCodes = [];
            for (let i = 0; i < this.needQueryDictCodes.length; i++) {
                let code = this.needQueryDictCodes[i];
                let list = this.queryDictCodeListMap[code];
                if (!(list && list.length > 0 && Array.isArray(list))) {
                    realNeedQueryDictCodes.push(code);
                }
            }
            if (realNeedQueryDictCodes && realNeedQueryDictCodes.length > 0) {
                postAction('/sys/dict/getDictTimeMapByCodes',realNeedQueryDictCodes)
                    .then(res => {
                        this.queryDictCodeListMap = {...res.result}
                    })
            }

        }

    },
    mounted() {

        if (!this.disableMixinMounted) {

            this.handleQuery()
        }

    },
    computed: {
        // 防抖
        filterMethodThrottle() {
            var time = null
            return (param) => {
                if (time) {
                    clearTimeout(time)
                }
                time = setTimeout(() => {
                    // 搜索方法
                    this.filterList(param)
                    clearTimeout(time)
                }, 500)
            }
        }
    },
    methods: {

        getObjByValue(list, value) {
            let obj = list.find(function (item) {
                return item.value == value;
            });
            if (obj) {
                return obj;
            } else {
                return {};
            }
        },
        //将集合转为map
        listToMap(list, name) {
            var map = {};
            for (let i=0;i<list.length;i++) {
                map[list[i][name]] = list[i];
            }
            return map;
        },
        //下拉加载
        loadmore() {
            this.formData.pageIndex++;
            this.getMorePortlist(this.formData);
        },
        getMorePortlist(v) {
            let num = ~~this.formData.pageIndex * ~~this.formData.pageSize;
            this.bdPortList = this.allBdportList.filter((item, index, arr) => {
                return index < num;
            });
        },
        //下拉框截取
        filterList(query) {
            if (!query) {
                this.bdPortList = this.allBdportList.slice(0, 100)
            } else {
                let result = []                                                        //存储符合条件的下拉选项
                this.allBdportList.forEach(val => {
                    if (val.label && val.label.indexOf(query) !== -1) result.push(val)
                })
                this.bdPortList = result.slice(0, 100)                                 //只取前100个
            }
        },

        handleSizeChange(val) {
            this.ipagination.currentPage = 1
            this.ipagination.pageSize = val
            this.handleQuery()
        },
        handleCurrentChange(val) {
            this.ipagination.currentPage = val
            this.handleQuery()
        },
        handleReset() {
            this.queryForm = {};
            this.filterForm = {};
            this.ipagination.currentPage = 1
            this.handleQuery()
        },
        handleResetFilter() { // 清空表格过滤器
            this.$refs.GTable.handlePureClear()
            this.filterForm = {}
        },
        handleResetAll() {
            this.handleResetFilter()
            this.handleReset()
        },
        handleSearch() {
            this.ipagination.currentPage = 1
            this.handleQuery()
        },
         getDictList(code) {
             if(sessionStorage.getItem("dict:"+code)|| (this.$store.state.dict && this.$store.state.dict[code] && Array.isArray(this.$store.state.dict[code]))) {
                this.dictlist = JSON.parse(sessionStorage.getItem("dict:"+code)) || this.$store.state.dict[code];
                // console.log('local有这个数据:',sessionStorage.getItem('dict:'+code));
                //存到前端页面缓存中
                this.$set(this.dictMap, code, this.listToMap(this.dictlist, 'value'));
                // console.log('session获取字典map:',code,this.dictMap);
            } else {
                 if (this.dictMap && this.dictMap[code]){

                 }else {
                     getAction(this.url.dict + code).then(res => {
                         this.dictlist = res.result;
                         // if (this.removeValue){
                         //     this.dictlist.filter(i=>{
                         //         return i.value != this.removeValue;
                         //     })
                         // }
                         // this.options = this.allOptions.splice(0,20)
                         // console.log('查询完后端存到前端缓存中:',this.dictlist);

                         // console.log('后端获取字典map:',code,this.dictMap);
                         //存到前端页面缓存中
                         // if(this.dictlist && this.dictlist.length>0 && Array.isArray(this.dictlist)) {
                         //     this.$set(this.dictMap, code, this.listToMap(this.dictlist, 'value'));
                         //     // sessionStorage.setItem('dict:' + code, JSON.stringify(this.dictlist));
                         //     this.$store.commit('dict/setDict', {
                         //         key: code,
                         //         value: this.dictlist
                         //     })
                         // }
                         if(this.dictlist && this.dictlist.length>0  && Array.isArray(this.dictlist)) {
                             this.$set(this.dictMap, code, this.listToMap(this.dictlist, 'value'));
                             //存到前端页面缓存中-大于1000条存储到store里面
                              if(this.dictlist.length > 1000) {
                                this.$store.commit('dict/setDict', {
                                    key: code,
                                    value: this.dictlist
                                })
                              } else {
                                sessionStorage.setItem("dict:" + code,JSON.stringify(this.dictlist));
                              }
                         }
                     })
                 }

            }
        },
        parseDict(val, code) {
            //如果val带有,证明是多个
            // console.log('要渲染的value',val);
            if (val && val.length>0 && Array.isArray(val)) {
                let ss = '';
                for (var i = 0; i < val.length; i++) {
                    // console.log('部门id',list[i]);
                    if (this.dictMap[code] && this.dictMap[code][val[i]]) {
                        ss += this.dictMap[code][val[i]]['text'] + ",";
                    } else {
                        ss += "";
                    }
                }
                return ss;
            }else if (val && val.length===0 && Array.isArray(val)){
                return "";
            }
            if (val && val.indexOf(',') !== -1) {
                let ss = '';
                let list = val.split(',');
                for (var i = 0; i < list.length; i++) {
                    // console.log('部门id',list[i]);
                    if (this.dictMap[code] && this.dictMap[code][list[i]]) {
                        ss += this.dictMap[code][list[i]]['text'] + ",";
                    } else {
                      ss += "";
                    }
                }
                ss = ss.substring(0, ss.lastIndexOf(','));
                if (ss.length>1){
                    return ss;
                }
                return val;
            }  else if (val) {
                if (this.dictMap[code] && this.dictMap[code][val]) {
                    return this.dictMap[code][val]['text'];
                } else {
                    return val;
                }

            } else {
                return val;
            }

        },
        parseDict2(val, code) {

            let flag = false;
            if (!val) {
                return "";
            }
            let list = [];
            let valueKey = "itemValue";
            let labelKey = "itemText";
            if (this.GLOBAL.dictListMap[code] && this.GLOBAL.dictListMap[code].length > 0 && Array.isArray(this.GLOBAL.dictListMap[code])) {
                list = this.GLOBAL.dictListMap[code];
                valueKey = "value";
                labelKey = "label";
                flag = true;
            }else {
                list = this.queryDictCodeListMap[code];
            }

            if (list == null || list.length === 0 ||  !Array.isArray(list)) {
                return val;
            }
            let map = this.listToMap(list,valueKey)

            //如果val带有,证明是多个
            if (flag) {

                console.log('要渲染的value',map[val][labelKey],code);
            }

            if (Array.isArray(val)) {
                return this.getValueFromMapKey2(map,labelKey,val);
            }else if (val && val.indexOf(',') !== -1) {
                let vals = val.split(',');
                return this.getValueFromMapKey2(map,labelKey,vals);
            }else {
                return this.getValueFromMapKey(map,val,labelKey);
            }


        },
        getValueFromMapKey(map,valueKey,labelKey) {
            if (!map) {
                return "";
            }
            let obj = map[valueKey];
            if (!obj) {
                return "";
            }
            let val = obj[labelKey];
            if (!val) {
                return "";
            }
            return val;
        },
        getValueFromMapKey2(map,labelKey,values) {
            if (!map) {
                return "";
            }

            if (!values || values.length === 0) {
                return "";
            }
            let str = "";
            for (let valuesKey in values) {
                let s = this.getValueFromMapKey(map,valuesKey,labelKey);
                if (s && s.length > 0 && str.indexOf(s) === -1) {
                    str += s + ",";
                }
            }
            str = str.substring(0, str.lastIndexOf(','));
            return str;
        },
        parseArticleType(val) {
            // console.log('文章类别map',this.allListMap);
            if (this.allListMap[val]) {
                return this.allListMap[val]['label'];
            }
        },
        initAllList() {
            getAllList().then(res => {
                if (res.success) {
                    for (var i = 0; i < res.result.length; i++) {
                        var obj = {};
                        obj.value = res.result[i].id;
                        obj.label = res.result[i].articleType;
                        this.allList.push(obj);
                    }
                    this.allListMap = this.listToMap(this.allList, 'value');
                }
            }).catch(err => {
                this.$message.error(err)
            })
        },
         handleQuery() {
             this.tableData = [];
            this.loading = true
            let form = {...this.queryForm, ...this.filterForm, ...this.initData}
            // for (let item in form) {
            //     if (typeof form[item] === 'string') {
            //         form[item] = form[item].trim()
            //         if (form[item] === '') {
            //             form[item] = null
            //         }
            //     }
            // }
             //清空字符串的前后空格
             this.trimParamsStr(form);
            const {prop, order} = this.isorter
            // 传入的参数
            const params = {
                ...form,
                column: prop,
                order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
            }
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === 'startTime') {
                    this.$set(params, 'column', 'startTime')
                } else if (this.enabledCreatetime){
                    this.$set(params, 'column', 'createTime')
                }
                //提货中查询
                if (this.queryForm.column === 'distributionTime') {
                    this.$set(params, 'column', 'distributionTime')
                }
                //排序
                //提货中查询
                if (this.queryForm.column === 'sort') {
                    this.$set(params, 'column', 'sort')
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage
                params.pageSize = this.ipagination.pageSize
            }
            // let method = "GET";
            // if (this.isUsePost) {
            //     method = "POST";
            // }
             if (!this.isUsePost) {
                 getAction(this.url.list, params).then((res) => {
                     // consoloe.log(res)
                     if (res.success) {
                         this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                         if (res.result) {
                             const {records, total} = res.result
                             this.tableData = records || res.result;
                             this.ipagination && (this.ipagination.total = total || res.result.length)
                         } else {

                            this.initTableData();
                         }

                     }
                     this.loading = false;
                     this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
                 }).catch(err => {
                     this.loading = false;
                     // this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
                 })
             }else {
                 postAction(this.url.list, params).then((res) => {
                     // consoloe.log(res)
                     if (res.success) {
                         this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                         if (res.result) {
                             const {records, total} = res.result
                             this.tableData = records || res.result;
                             if (this.customerFind && !this.tableData.length && this.url.checkCustomerHaveCooperation && this.queryForm.username) {
                                 getAction(this.url.checkCustomerHaveCooperation, { customerName : this.queryForm.username }).then((res) => {
                                     if (res.success === true) {
                                         this.$message.success(res.message);
                                     } else {
                                         this.$message.warning(res.message);
                                     }
                                 }).catch(err => {
                                 })
                             }
                             this.ipagination && (this.ipagination.total = total || res.result.length)

                         } else {

                            this.initTableData();
                         }

                     }
                     this.loading = false;
                     this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
                 }).catch(err => {
                     this.loading = false;
                    this.initTableData();
                     // this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
                 })
             }

        },
        initTableData(){
            this.tableData = [];
            this.ipagination = {
                currentPage: 1,
                pageSize: 50,
                pageSizes: [50, 100, 200, 300, 400, 500],
                total: 0
            };
        },
        handleTableChange({prop, order}) {
            if (order === null) {
                this.handleResetSorter()
            } else {
                this.$set(this.isorter, 'prop', prop)
                this.$set(this.isorter, 'order', order)
            }
            this.handleSearch()
        },
        //解析对象 返回queryString字符串，返回url
        parseUrlToQuertString(url,params){
            let ss = "";
            for (var name in params) {
                    ss += name + "=" + params[name] +"&";
            }
            if (ss && ss.length>0){
                ss = ss.substring(0,ss.length-1);
            }
            if (url && url.indexOf("?")!==-1) {
                return url+"&"+ss;
            }else {
                return url+"?"+ss;
            }
        },
        //打开链接
        openLink(url,params) {
            // let baseUrl = "http://192.168.1.128:9000";//大鹏的baseurl
            let baseUrl = "http://8.135.121.105:9000";//线上服务的baseurl
            window.open(baseUrl + this.parseUrlToQuertString(url,params));
        },
        handleResetSorter() {
            // this.$set(this.isorter, 'prop', 'createTime')
            this.$set(this.isorter, 'order', 'descending')
        },
        handleTableQueryChange(form, flag, clearSorter = false) {
            this.filterForm = {...form}
            clearSorter && this.handleResetSorter()
            // flag && this.handleQuery()
        },
        handleClearSelection() {
            this.selectionRows = []
            this.selectedRowKeys = []
            this.selectedRowOrderNos = [];
            this.selectedRowOrderNosCheck = [];
            this.$refs.GTable.handleClearSelection()
        },
        handleSelectionChange(selection) { // 多选
            // consoloe.log();
            let arr = []
            this.nameList = []
            this.cnamelist = []
            this.emailList = []
            this.selectedRowOrderNos=[]
            this.selectedRowOrderNosCheck = [];
            for (let i = 0; i < selection.length; i++) {
                arr.push(selection[i]['id'])
                this.nameList.push(selection[i]['username'])
                this.cnamelist.push(selection[i]['cname'])
                this.emailList.push(selection[i]['email'])
                this.selectedRowOrderNos.push(selection[i]['orderNo'])
                this.selectedRowOrderNosCheck.indexOf(selection[i]['orderNo']) == -1 ? this.selectedRowOrderNosCheck.push(selection[i]['orderNo']) : '';
            }
            this.selectionRows = selection
            this.selectedRowKeys = [...arr];
            // consoloe.log('this.selection', selection)

        },
        handleTableCurrentChange(currentRow) { // 单选

            this.selectionRows = [currentRow]
            this.selectedRowKeys = [currentRow['id']]
        },
        //导出excel xlsx
        exportExcelPlusold(url,fileName) {
            // this.$store.commit("setFullscreenLoading", true);
            this.exportLoading = true;
            //let form = {...this.queryForm, ...this.filterForm, ...this.initData}
            let params = {...this.queryForm, ...this.filterForm,...this.initData};
            if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
                params['selections'] = this.selectedRowKeys.join(',');
                params['ids'] = this.selectedRowKeys.join(',')
            } else {
                params['ids'] = '';
            }
            //清空字符串的前后空格
            this.trimParamsStr(params);
            this.loading = true;
            let that = this;
            postRefreshAction(url, params, {responseType: 'blob'}).then((data) => {
                this.loading = false;
                this.exportLoading = false;
                // this.$store.commit("setFullscreenLoading", false);
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xlsx')
                } else {
                    let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', fileName + '.xlsx');
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                }
            }).catch(err=>{
                this.loading = false;
                this.exportLoading = false;
                // this.$store.commit("setFullscreenLoading", false);
            })
        },

        handleExportXls(fileName) {
            this.exportLoading = true;
            this.loading = true;
            // this.$store.commit("setFullscreenLoading", true);
            if (!fileName || typeof fileName != 'string') {
                fileName = '导出文件'
            }
            if (fileName === '正常产品' || fileName === '所有产品') {
                this.$confirm(`导出的数据量比较庞大，需要等待1-2分钟?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                }).then(() => {
                    this.exportExcel(fileName);
                }).catch(() => {

                })
            } else {
                this.exportExcel(fileName);
            }
        },

        exportExcel(fileName) {
            const {prop, order} = this.isorter
            let params = {
                ...this.queryForm,
                ...this.filterForm,
                column: prop,
                order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
            };

            // 传入的参数
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === 'startTime') {
                    this.$set(params, 'column', 'startTime')
                } else if (this.enabledCreatetime){
                    this.$set(params, 'column', 'createTime')
                }
                //提货中查询
                if (this.queryForm.column === 'distributionTime') {
                    this.$set(params, 'column', 'distributionTime')
                }
                //排序
                //提货中查询
                if (this.queryForm.column === 'sort') {
                    this.$set(params, 'column', 'sort')
                }
            }
            if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
                params['selections'] = this.selectedRowKeys.join(',');
                params['ids'] = this.selectedRowKeys.join(',');
                params['orderNo'] =this.selectedRowOrderNos.join(',');
            } else {
                params['ids'] = '';
            }
           //清空字符串的前后空格
            this.trimParamsStr(params);
            let that = this;
            postRefreshAction(this.url.exportXlsUrl, params, {responseType: 'blob'}).then((data) => {
                this.loading = false;
                this.exportLoading = false;
                // this.$store.commit("setFullscreenLoading", false);
                if (data.type === 'application/json') {
                    const reader = new FileReader()
                    reader.onload = function () {
                        const message = JSON.parse(reader.result).message
                        // that.$message.error(message);// 将错误信息显示出来
                    }
                    reader.readAsText(data)
                    return false;
                }else if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
                } else {
                    let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', fileName + '.xls');
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                }
            }).catch(err=>{
                this.loading = false;
                this.exportLoading = false;
                // this.$store.commit("setFullscreenLoading", false);
            })
        },

        //下载excel模板
        //下载产品导入模板
        downloadModel(url, fileName) {
            let that = this;
            let params = {...this.queryForm, ...this.filterForm}
            postAction(url, params, {responseType: 'blob'})
                .then(response => {
                    if (!response) {
                        return
                    }

                    const blob = new Blob([response])
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob, fileName)
                    } else {
                        let u = window.URL.createObjectURL(response)
                        let aLink = document.createElement('a')
                        aLink.style.display = 'none'
                        aLink.href = u
                        aLink.setAttribute('download', fileName + '.xls')
                        document.body.appendChild(aLink)
                        aLink.click()
                        document.body.removeChild(aLink)
                        window.URL.revokeObjectURL(u)
                    }
                    // var blob = new Blob([response],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
                    // var downloadElement = document.createElement('a');
                    // var herf = window.URL.createObjectURL(blob);
                    // downloadElement.herf = herf;
                    // downloadElement.download = fileName + ".xlsx";
                    // document.body.appendChild(downloadElement);
                    // downloadElement.click();
                    // document.body.removeChild(downloadElement);
                    // window.URL.revokeObjectURL(herf);
                })
        },
        downloadExcel(url, fileName, params) {
            let that = this;
            getAction(url, params, {responseType: 'blob'})
                .then(response => {
                    if (!response) {
                        return
                    }
                    if (response.type === 'application/json') {
                        const reader = new FileReader()
                        reader.onload = function () {
                            const message = JSON.parse(reader.result).message
                            // that.$message.error(message);// 将错误信息显示出来
                        }
                        reader.readAsText(response)
                        return false;
                    }else {
                        const blob = new Blob([response])
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            navigator.msSaveBlob(blob, fileName)
                        } else {
                            let u = window.URL.createObjectURL(response)
                            let aLink = document.createElement('a')
                            aLink.style.display = 'none'
                            aLink.href = u
                            aLink.setAttribute('download', fileName +  '.xls')
                            document.body.appendChild(aLink)
                            aLink.click()
                            document.body.removeChild(aLink)
                            window.URL.revokeObjectURL(u)
                        }
                    }

                })
        },
        downloadExcelSale(url, fileName, params) {
            let user = JSON.parse(localStorage.getItem("USERINFO"));
            console.log("user.roleName--->",user.roleName)
            if(user.roleName !=="销售专员"){
                // 如果是销售就不需要判断条件，销售以上的级别则需要添加条件才能导出
                if ((params.createTimeStart == '' || params.createTimeStart == undefined)
                && (params.createTimeEnd == '' || params.createTimeEnd == undefined)
                && (params.loseMoney == '' || params.loseMoney == undefined)
                && (params.recLock == '' || params.recLock == undefined)
                && (params.salesman == '' || params.salesman == undefined)
                && (params.customer == '' || params.customer == undefined)
                    && (params.orderNo == '' || params.orderNo == undefined) && (params.deptIdList == '' || params.deptIdList == undefined)) {
                this.$message.warning('导出excel必须添加查询条件')
                return
                }
            }
            let that = this;
            postAction(url, params, {responseType: 'blob'})
                .then(response => {
                    if (!response) {
                        return
                    }
                    if (response.type === 'application/json') {
                        const reader = new FileReader()
                        reader.onload = function () {
                            const message = JSON.parse(reader.result).message
                            // that.$message.error(message);// 将错误信息显示出来
                        }
                        reader.readAsText(response)
                        return false;
                    }else {
                        const blob = new Blob([response])
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            navigator.msSaveBlob(blob, fileName)
                        } else {
                            let u = window.URL.createObjectURL(response)
                            let aLink = document.createElement('a')
                            aLink.style.display = 'none'
                            aLink.href = u
                            aLink.setAttribute('download', fileName +  '.xls')
                            document.body.appendChild(aLink)
                            aLink.click()
                            document.body.removeChild(aLink)
                            window.URL.revokeObjectURL(u)
                        }
                    }

                })
        },
        downloadExcelComplete(url, fileName, params) {
            //订单号-操作员-柜号-目的地-锁单时间
            if ((params.orderNo == '' || params.orderNo == undefined)
            && (params.operateId == '' || params.operateId == undefined)
            && (params.podEnd == '' || params.podEnd == undefined)) {
                this.$message.warning('导出excel必须添加查询条件（订单号/操作员/目的地）')
                    return
            }
            let that = this;
            getAction(url, params, {responseType: 'blob'})
                .then(response => {
                    if (!response) {
                        return
                    }
                    if (response.type === 'application/json') {
                        const reader = new FileReader()
                        reader.onload = function () {
                            const message = JSON.parse(reader.result).message
                            // that.$message.error(message);// 将错误信息显示出来
                        }
                        reader.readAsText(response)
                        return false;
                    }else {
                        const blob = new Blob([response])
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            navigator.msSaveBlob(blob, fileName)
                        } else {
                            let u = window.URL.createObjectURL(response)
                            let aLink = document.createElement('a')
                            aLink.style.display = 'none'
                            aLink.href = u
                            aLink.setAttribute('download', fileName +  '.xls')
                            document.body.appendChild(aLink)
                            aLink.click()
                            document.body.removeChild(aLink)
                            window.URL.revokeObjectURL(u)
                        }
                    }

                })
        },
        downloadExcel2(url, params,fileName, fileType) {
            let that = this;
            getAction(url, params, {responseType: 'blob'})
                .then(response => {
                    if (!response) {
                        return
                    }
                    if (response.type === 'application/json') {
                        const reader = new FileReader()
                        reader.onload = function () {
                            const message = JSON.parse(reader.result).message
                            // that.$message.error(message);// 将错误信息显示出来
                        }
                        reader.readAsText(response)
                        return false;
                    }else {
                        const blob = new Blob([response])
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            navigator.msSaveBlob(blob, fileName)
                        } else {
                            let u = window.URL.createObjectURL(response)
                            let aLink = document.createElement('a')
                            aLink.style.display = 'none'
                            aLink.href = u
                            aLink.setAttribute('download', fileName + (fileType || '.xls'))
                            document.body.appendChild(aLink)
                            aLink.click()
                            document.body.removeChild(aLink)
                            window.URL.revokeObjectURL(u)
                        }
                    }

                })
        },
        downloadExcelPost(url, fileName, params, fileType) {
            let that = this;
            // this.$store.commit("setFullscreenLoading", true);
            postAction(url, params, {responseType: 'blob'})
                .then(response => {
                    // this.$store.commit("setFullscreenLoading", false);
                    if (!response) {
                        return
                    }
                    if (response.type === 'application/json') {
                        const reader = new FileReader()
                        reader.onload = function () {
                            const message = JSON.parse(reader.result).message
                            // that.$message.error(message);// 将错误信息显示出来
                        }
                        reader.readAsText(response)
                        return false;
                    }else{
                        const blob = new Blob([response])
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            navigator.msSaveBlob(blob, fileName)
                        } else {
                            let u = window.URL.createObjectURL(response)
                            let aLink = document.createElement('a')
                            aLink.style.display = 'none'
                            aLink.href = u
                            aLink.setAttribute('download', fileName + (fileType || '.xls'))
                            document.body.appendChild(aLink)
                            aLink.click()
                            document.body.removeChild(aLink)
                            window.URL.revokeObjectURL(u)
                        }
                    }

                }).catch(()=>{
                // this.$store.commit("setFullscreenLoading", false);
            })
        },
        // 直接打印后台返回的pdf
        printPdfDoc(url, params, filename, method) {
            printPdfDoc(url, params, filename, method);
        },
        //预览pdf
        preview(url, params, fileName) {
            let that = this;
            // this.$store.commit("setFullscreenLoading", true);
            downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("GET",url,params,fileName +".pdf",that);
            // getAction(url, params, {responseType: 'blob'})
            //     .then(res => {
            //         if (res.type === 'application/json') {
            //             const reader = new FileReader()
            //             reader.onload = function () {
            //                 const message = JSON.parse(reader.result).message
            //                 that.$message.error(message);// 将错误信息显示出来
            //             }
            //             reader.readAsText(res)
            //             return false;
            //         }else {
            //             // this.$store.commit("setFullscreenLoading", false);
            //             var binaryData = [];
            //             binaryData.push(res);
            //             let urlencoded = window.URL.createObjectURL(new Blob(binaryData,
            //                 {"type": "application/pdf"}));
            //             if(fileName) {
            //                 var dllink = document.createElement('a');
            //                 dllink.href = urlencoded;
            //                 dllink.setAttribute("download", fileName + ".pdf"); // Added Line
            //                 dllink.click();
            //             } else {
            //                 window.open(urlencoded)
            //             }
            //         }
            //
            //         // 打开之后再下载
            //         // window.open("/static/pdf/web/viewer.html?filename=" + fileName + "&file=" + encodeURIComponent(urlencoded));
            //     }).catch(()=>{
            //     // this.$store.commit("setFullscreenLoading", false);
            // })
        },
        previewHtml(url, params) {
            let that = this;
            // this.$store.commit("setFullscreenLoading", true);
            getAction(url, params, {responseType: 'blob'})
                .then(res => {
                    if (res.type === 'application/json') {
                        const reader = new FileReader()
                        reader.onload = function () {
                            const message = JSON.parse(reader.result).message
                            // that.$message.error(message);// 将错误信息显示出来
                        }
                        reader.readAsText(res)
                        return false;
                    }else {
                        var binaryData = [];
                        binaryData.push(res);
                        let urlencoded = window.URL.createObjectURL(new Blob(binaryData,
                            {"type": "text/html"}));
                        window.open(urlencoded)
                    }
                    // this.$store.commit("setFullscreenLoading", false);

                }).catch(()=>{
                // this.$store.commit("setFullscreenLoading", false);
            })
        },
        downloadPdf(path, params, fileName) {
            let that = this;
            // this.$store.commit("setFullscreenLoading", true);
            getAction(path, params, {responseType: 'blob'})
                .then(res => {
                    if (res.type === 'application/json') {
                        const reader = new FileReader()
                        reader.onload = function () {
                            const message = JSON.parse(reader.result).message
                            // that.$message.error(message);// 将错误信息显示出来
                        }
                        reader.readAsText(res)
                        return false;
                    }else{
                        // this.$store.commit("setFullscreenLoading", false);
                        let url = window.URL.createObjectURL(new Blob([res]));  // new Blob([data])用来创建URL的file对象或者blob对象
                        let link = document.createElement('a');
                        link.style.display = 'none';
                        link.href = url;
                        link.download = fileName + '.pdf'; //docNumber 动态文件名
                        document.body.appendChild(link);
                        link.click();
                    }

                }).catch(()=>{
                // this.$store.commit("setFullscreenLoading", false);
            })

        },

        getDateStr(){
            let date = new Date();
            let seperator = '-';
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate()
            if(month >= 1 && month <= 9){
                month = '0' + month
            }
            if(day >= 0 && day <= 9){
                day = '0' + day
            }
            return year + seperator + month + seperator + day
        },
        //清空字符串前后空格
        trimParamsStr(form) {
            if (form) {
                for (let item in form) {
                    if (typeof form[item] === 'string') {
                        form[item] = form[item].trim()
                        if (form[item] === '') {
                            form[item] = null
                        }
                    }
                }
            }

        },
        // isSelect是否必须要查询条件判断
        exportExcelPlus(url,fileName) {
            const {prop, order} = this.isorter
            //let form = {...this.queryForm, ...this.filterForm, ...this.initData}
            let params = {...this.queryForm, ...this.filterForm,...this.initData,
                column: prop,
                order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',};
            if (['快递间隔半年下单报表', '应付账龄','扫码出库记录','扫码记录','整柜报价','快递订单利润','快递订单利润明细','快递渠道利润','已清关品名'].indexOf(fileName) === -1){
                let queryFormNow =  {...this.queryForm}
                if (Object.keys(queryFormNow).length === 0) {
                    this.$message.warning('导出excel必须添加查询条件')
                    return
                }
            }
            if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
                params['selections'] = this.selectedRowKeys.join(',');
                params['ids'] = this.selectedRowKeys.join(',')
                params['selectedIdList'] = this.selectedRowKeys
            } else {
                params['ids'] = '';
            }
            //清空字符串的前后空格
            this.trimParamsStr(params);
            if (fileName == '订单利润已复核导出'){
                const {order} = this.isorter
                params['order'] = order.indexOf('asc') !== -1 ? 'asc' : 'desc';
            }
            let that = this;
            postRefreshAction(url, params, {responseType: 'blob'}).then((data) => {
                //// 处理下载失败的问题，data是得到的请求结果
                if (data.type === 'application/json') {
                    const reader = new FileReader()
                    reader.onload = function () {
                        const message = JSON.parse(reader.result).message
                        // that.$message.error(message);// 将错误信息显示出来
                    }
                    reader.readAsText(data)
                    return false;
                }else if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xlsx')
                } else {
                    let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', fileName + '.xlsx');
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                }
                // const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                // const a = document.createElement('a')
                // const href = window.URL.createObjectURL(blob) // 创建下载的链接
                // a.href = href
                // a.download = filename // 下载后文件名
                // document.body.appendChild(a)
                // a.click() // 点击下载
                // document.body.removeChild(a) // 下载完成移除元素
                // window.URL.revokeObjectURL(href) // 释放掉blob对象

            })
        },
        getColorByName(colorName){
            if(colorName && colorName.length > 0) {
                if (colorName.indexOf("红") !== -1) {
                    return 'table_red_bg'
                } else if (colorName.indexOf("绿") !== -1) {
                    return 'table_green_bg'
                } else if (colorName.indexOf("黄") !== -1) {
                    return 'table_yellow_bg'
                }
            }
        },
        compare2StrIsNotEq(s1,s2){
        // <span v-if="scope.row.shipmentCode && scope.row.shipmentCode.toLowerCase() !==  scope.row.lineWarehouseCode.toLowerCase().trim() && scope.row.lineWarehouseCode!= null && scope.row.lineWarehouseCode!= '' " style="color: red">{{scope.row.shipmentCode}}</span>
            if (!s2) {
                //没有柜子专线代码 不需要变红
                return false;
            }
            if (!s1) {
                //有柜子专线代码 并且 订单没有仓库代码 要变红
                return true;
            }
            s1 = s1.trim();
            s1 = s1.toLowerCase();
            s2 = s2.trim();
            s2 = s2.toLowerCase();
            return s1!==s2;
        },
        getColorFromStr(s1,s2){
            if (this.compare2StrIsNotEq(s1,s2)) {
                return 'color:red';
            }else {
                return '';
            }
        },
        caculateCmbtv(cmb,tv){

            if (!tv) {
                tv = 0;
            }
            if (!cmb) {
                return 0;
            }
            return (Number(tv)/Number(cmb)).toFixed(3);
        }



    },
}
